"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.buildMenus = buildMenus;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.getMenuSuperior = getMenuSuperior;
exports.getMenus = getMenus;
exports.getMenusTree = getMenusTree;
require("core-js/modules/es6.array.of");
var _request = _interopRequireDefault(require("@/utils/request"));
function getMenusTree(pid) {
  return (0, _request.default)({
    url: 'api/menus/lazy?pid=' + pid,
    method: 'get'
  });
}
function getMenus(params) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'get',
    params: params
  });
}
function getMenuSuperior(ids) {
  var data = ids.length ? ids : Array.of(ids);
  return (0, _request.default)({
    url: 'api/menus/superior',
    method: 'post',
    data: data
  });
}
function buildMenus() {
  return (0, _request.default)({
    url: 'api/menus/build',
    method: 'get'
  });
}
function add(data) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'post',
    data: data
  });
}
function del(ids) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'delete',
    data: ids
  });
}
function edit(data) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'put',
    data: data
  });
}
var _default = {
  add: add,
  edit: edit,
  del: del,
  getMenusTree: getMenusTree,
  getMenuSuperior: getMenuSuperior,
  getMenus: getMenus
};
exports.default = _default;