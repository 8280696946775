"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
_vue.default.use(_vueRouter.default);
var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/third',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    // meta: { title: '首页', icon: 'index', affix: true, noCache: true }
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: false
    }
  }]
},
// 版本控制
// {
//   path: '/version',
//   component: Layout,
//   redirect: '/version',
//   children: [
//     {
//       path: 'version',
//       component: (resolve) => require(['@/views/version/list'], resolve),
//       name: 'version',
//       meta: {
//         icon: 'version',
//         title: '版本管理',
//         affix: false,
//         noCache: true
//       }
//     },
//     {
//       path: 'edit',
//       hidden: true,
//       component: (resolve) => require(['@/views/version/edit'], resolve),
//       name: 'edit',
//       meta: {
//         title: '修改版本'
//       }
//     }
//   ]
// },

// {
//   path: '/banner',
//   component: Layout,
//   redirect: '/banner',
//   children: [{
//     path: 'banner',
//     component: (resolve) => require(['@/views/banner/list'], resolve),
//     name: 'Banner',
//     meta: {
//       icon: 'banner',
//       title: '测试banner管理',
//       affix: false,
//       noCache: true
//     }
//   }, {
//     path: 'edit',
//     hidden: true,
//     component: (resolve) => require(['@/views/banner/edit'], resolve),
//     name: 'edit',
//     meta: {
//       title: '测试banner新建'
//     }
//   }]

// },
// {
//   path: '/push',
//   component: Layout,
//   redirect: '/push',
//   children: [{
//     path: 'push',
//     component: (resolve) => require(['@/views/push/list'], resolve),
//     name: 'Push管理',
//     meta: {
//       icon: 'pushpin',
//       title: '测试push管理',
//       affix: false,
//       noCache: true
//     }
//   }, {
//     path: 'add',
//     hidden: true,
//     component: (resolve) => require(['@/views/push/add'], resolve),
//     name: 'add',
//     meta: {
//       title: '测试Push新增'
//     }
//   }]
// },

// 活动相关配置测试路由
// {
//   path: '/activity',
//   component: Layout,
//   redirect: '/activity',
//   meta: {
//     icon: 'peoples',
//     title: '活动相关配置'
//   },
//   children: [
//   {
//     path: 'activityTheme',
//     component: (resolve) => require(['@/views/activity/activityTheme'], resolve),
//     name: 'Meetup活动主题管理',
//     meta: {
//       icon: 'peoples',
//       title: 'Meetup活动主题管理'
//       // affix: false,
//       // noCache: true
//     }
//   }, 
//   {
//     path: 'activityThemeAdd',
//     component: (resolve) => require(['@/views/activity/activityTheme/add'], resolve),
//     name: '添加',
//     meta: {
//       icon: 'peoples',
//       title: '添加'
//       // affix: false,
//       // noCache: true
//     }
//   }, 
//   {
//     path: 'activity',
//     component: (resolve) => require(['@/views/activity/activity'], resolve),
//     name: '活动管理',
//     meta: {
//       icon: 'peoples',
//       title: '活动管理'
//       // affix: false,
//       // noCache: true
//     }
//   }, 
//   {
//     path: 'activityPoster',
//     component: (resolve) => require(['@/views/activity/activityPoster'], resolve),
//     name: '生成活动海报页面',
//     meta: {
//       icon: 'peoples',
//       title: '生成活动海报页面'
//       // affix: false,
//       // noCache: true
//     }
//   }, 
//   {
//     path: 'activityImage',
//     component: (resolve) => require(['@/views/activity/activityPoster/image'], resolve),
//     name: '生成活动海报',
//     meta: {
//       icon: 'peoples',
//       title: '生成活动海报'
//     }
//   }, 

//   {
//     path: 'activityAdd',
//     component: (resolve) => require(['@/views/activity/activity/add'], resolve),
//     name: '添加活动',
//     meta: {
//       icon: 'peoples',
//       title: '添加活动'
//       // affix: false,
//       // noCache: true
//     }
//   }, 
//   {
//     path: 'activityselected',
//     component: (resolve) => require(['@/views/activity/activitySelected'], resolve),
//     name: '精选活动管理',
//     meta: {
//       icon: 'peoples',
//       title: '精选活动管理',
//       affix: false,
//       noCache: true
//     }
//   },
//   {
//     path: 'activityMain',
//     component: (resolve) => require(['@/views/activity/activityMain/list'], resolve),
//     name: '主推活动管理',
//     meta: {
//       icon: 'peoples',
//       title: '主推活动管理',
//       affix: false,
//       noCache: true
//     }
//   }, 
//   {
//     path: 'activityMain/add',
//     hidden: true,
//     component: (resolve) => require(['@/views/activity/activityMain/add'], resolve),
//     name: 'add',
//     meta: {
//       title: '测试主题活动新增',
//     }
//   },
// ]
// },

// 用户协议 、隐私政策 管理 privacy policy

// {
//   path: '/privacyPolicy',
//   component: Layout,
//   redirect: '/privacyPolicy',
//   meta: {
//     icon: 'peoples',
//     title: '隐私-协议'
//   },
//   children: [
//     {
//       path: 'priPoList',
//       component: (resolve) => require(['@/views/privacyPolicy/list'], resolve),
//       name: '隐私-协议',
//       meta: {
//         icon: 'peoples',
//         title: '隐私-协议'
//       }
//     },
//     {
//       path: 'priPoEdit',
//       hidden: true,
//       component: (resolve) => require(['@/views/privacyPolicy/edit'], resolve),
//       name: 'priPoEdit',
//       meta: {
//         title: '修改版本'
//       }
//     }
//   ]
// },

// // 群组管理
// {
//   path: '/groupManage',
//   component: Layout,
//   redirect: '/groupManage',
//   meta: {
//     icon: 'peoples',
//     title: '群组管理'
//   },
//   children: [
//     {
//       path: 'groupList',
//       component: (resolve) => require(['@/views/groupManage/list'], resolve),
//       name: '群组列表',
//       meta: {
//         icon: 'peoples',
//         title: '群组列表'
//       }
//     },
//     {
//       path: 'groupDetails',
//       component: (resolve) => require(['@/views/groupManage/details'], resolve),
//       name: '群组详情',
//       meta: {
//         icon: 'peoples',
//         title: '群组详情'
//       }
//     },
//   ]
// },

// // 官方号管理
// {
//   path: '/officialNum',
//   component: Layout,
//   redirect: '/officialNum',
//   meta: {
//     icon: 'peoples',
//     title: '官方号管理'
//   },
//   children: [
//     {
//       path: 'accountNumber',
//       component: (resolve) => require(['@/views/officialNum/accountNumber/list'], resolve),
//       name: '官方号管理',
//       meta: {
//         icon: 'peoples',
//         title: '官方号管理'
//       }
//     },
//     {
//       path: 'accountNumberAdd',
//       component: (resolve) => require(['@/views/officialNum/accountNumber/add'], resolve),
//       name: 'accountNumberAdd',
//     },
//   ]
// },

// // 活动号管理
// {
//   path: '/activityNum',
//   component: Layout,
//   redirect: '/activityNum',
//   meta: {
//     icon: 'peoples',
//     title: '活动号管理'
//   },
//   children: [
//     {
//       path: 'activityNum',
//       component: (resolve) => require(['@/views/activityNum/activityNum/list'], resolve),
//       name: '活动号管理',
//       meta: {
//         icon: 'peoples',
//         title: '活动号管理'
//       }
//     },
//     {
//       path: 'activityNumAdd',
//       component: (resolve) => require(['@/views/activityNum/activityNum/add'], resolve),
//       name: 'activityNum',
//     },
//   ]
// },

// 运营位相关配置
// {
//   path: '/operationPosition',
//   component: Layout,
//   redirect: '/operationPosition',
//   meta: {
//     icon: 'peoples',
//     title: '运营位相关配置'
//   },
//   children: [
//     {
//       path: 'banner',
//       component: (resolve) => require(['@/views/operationPosition/banner/list'], resolve),
//       name: 'banner管理',
//       meta: {
//         icon: 'peoples',
//         title: 'banner管理'
//       }
//     },
//     {
//       path: 'bannerAdd',
//       component: (resolve) => require(['@/views/operationPosition/banner/edit'], resolve),
//       name: '添加修改banner',
//     },
//     {
//       path: 'ad',
//       component: (resolve) => require(['@/views/operationPosition/ad/list'], resolve),
//       name: '广告',
//       meta: {
//         icon: 'peoples',
//         title: '广告'
//       }
//     },
//     {
//       path: 'adAdd',
//       component: (resolve) => require(['@/views/operationPosition/ad/edit'], resolve),
//       name: '添加修改ad',
//     },
//     {
//       path: 'homeOperationPosition',
//       component: (resolve) => require(['@/views/operationPosition/homeOperationPosition/list'], resolve),
//       name: '首页运营位管理',
//       meta: {
//         icon: 'peoples',
//         title: '首页运营位管理'
//       }
//     },
//     {
//       path: 'homeOperationPositionAdd',
//       component: (resolve) => require(['@/views/operationPosition/homeOperationPosition/edit'], resolve),
//       name: '首页运营位管理添加修改',
//     },
//     {
//       path: 'category',
//       component: (resolve) => require(['@/views/operationPosition/category/list'], resolve),
//       name: '品类管理',
//       meta: {
//         icon: 'peoples',
//         title: '品类管理'
//       }
//     },
//     {
//       path: 'categoryAdd',
//       component: (resolve) => require(['@/views/operationPosition/category/edit'], resolve),
//       name: '添加修改品类',
//     },
//   ]
// },

// 活动号管理
// {
//   path: '/activityNum',
//   component: Layout,
//   redirect: '/activityNum',
//   meta: {
//     icon: 'peoples',
//     title: '活动号管理'
//   },
//   children: [{
//     path: 'activityNum',
//     component: (resolve) => require(['@/views/activityNum/activityNum/list'], resolve),
//     name: '活动号管理',
//     meta: {
//       icon: 'peoples',
//       title: '活动号管理'
//       // affix: false,
//       // noCache: true
//     }
//   },
//   {
//     path: 'activityNumAdd',
//     component: (resolve) => require(['@/views/activityNum/activityNum/add'], resolve),
//     name: 'activityNum',
//   },
//   {
//     path: 'activityNumApply',
//     component: (resolve) => require(['@/views/activityNum/activityNumApply/list'], resolve),
//     name: '活动号申请管理',
//     meta: {
//       icon: 'peoples',
//       title: '活动号申请管理'
//     }
//   },
//   {
//     path: 'activityGroup',
//     component: (resolve) => require(['@/views/activityNum/activityGroup/list'], resolve),
//     name: '关联群组管理',
//     meta: {
//       icon: 'peoples',
//       title: '关联群组管理'
//     }
//   }]
// },

// // 小队相关配置
// {
//   path: '/team',
//   component: Layout,
//   redirect: '/team',
//   meta: {
//     icon: 'peoples',
//     title: '小队相关配置'
//   },
//   children: [
//     {
//       path: 'teamType',
//       component: (resolve) => require(['@/views/team/teamType/list'], resolve),
//       name: '小分队类型管理',
//       meta: {
//         icon: 'peoples',
//         title: '小分队类型管理'
//       },
//     },
//     {
//         path: 'teamType/teamTypeAdd',
//         component: (resolve) => require(['@/views/team/teamType/add'], resolve),
//         name: '小队类型新建',
//     },
//     {
//       path: 'cityLight',
//       component: (resolve) => require(['@/views/team/cityLight/list'], resolve),
//       name: '城市点亮管理',
//       meta: {
//         icon: 'peoples',
//         title: '城市点亮管理'
//       }
//     },
//     {
//       path: 'cityLight/setCity',
//       component: (resolve) => require(['@/views/team/cityLight/add'], resolve),
//       name: '城市设置',
//       meta: {
//         icon: 'peoples',
//         title: '城市设置'
//       },
//       hidden: true
//     },
//     {
//       path: 'cityLight/cityUserList',
//       component: (resolve) => require(['@/views/team/cityLight/cityUserList'], resolve),
//       name: '城市用户列表',
//       meta: {
//         icon: 'peoples',
//         title: '城市用户列表'
//       },
//       hidden: true
//     },
//     {
//       path: 'smallGroup',
//       component: (resolve) => require(['@/views/team/smallGroup/list'], resolve),
//       name: '小分队管理',
//       meta: {
//         icon: 'peoples',
//         title: '小分队管理'
//       }
//     },
//     // {
//     //   path: 'smallGroupUserList',
//     //   component: (resolve) => require(['@/views/team/smallGroup/smallGroupUserList'], resolve),
//     //   name: '小分队用户列表',
//     //   meta: {
//     //     icon: 'peoples',
//     //     title: '小分队用户列表'
//     //   }
//     // },
//     {
//       path: 'teamLeaderApply',
//       component: (resolve) => require(['@/views/team/smallGroup/teamLeaderApply'], resolve),
//       name: '小队长申请列表',
//       meta: {
//         icon: 'peoples',
//         title: '小队长申请列表'
//       }
//     },
//   ]
// },

{
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;
var _default = new _vueRouter.default({
  mode: 'hash',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
exports.default = _default;