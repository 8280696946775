"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/core-js/modules/es6.array.iterator.js");
require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/core-js/modules/es6.promise.js");
require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/core-js/modules/es6.object.assign.js");
require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _mavonEditor = _interopRequireDefault(require("mavon-editor"));
require("mavon-editor/dist/css/index.css");
var _Dict = _interopRequireDefault(require("./components/Dict"));
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/element-variables.scss");
require("./assets/styles/index.scss");
var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));
require("highlight.js/styles/atom-one-dark.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
require("echarts-gl");
var _moment = _interopRequireDefault(require("moment"));
var _vueBaiduMap = _interopRequireDefault(require("vue-baidu-map"));
var _vueQuillEditor = _interopRequireDefault(require("vue-quill-editor"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _kindeditor = _interopRequireDefault(require("kindeditor"));
require("kindeditor/kindeditor-all-min.js");
require("kindeditor/themes/default/default.css");
//

// 数据字典

// 权限指令

// global css

// 代码高亮

// icon
// permission control

// import styles
// for snow theme
// for bubble theme

_vue.default.use(_kindeditor.default);

// 定义全局时间戳过滤器
_vue.default.filter('formatDate', function (value) {
  return (0, _moment.default)(value).format('YYYY-MM-DD HH:mm:ss');
});
_vue.default.use(_vueQuillEditor.default);
_vue.default.use(_vueHighlightjs.default);
_vue.default.use(_mavonEditor.default);
_vue.default.use(_Permission.default);
_vue.default.use(_Dict.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});

_vue.default.use(_vueBaiduMap.default, {
  ak: 'suyv2jndXIaZeQD5yGGfBkzUZmuOFKTG'
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});