"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/renshi-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(username, password, code, uuid) {
  return (0, _request.default)({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      code: code,
      uuid: uuid
    }
  });
}
function getInfo() {
  return (0, _request.default)({
    url: 'auth/info',
    method: 'get'
  });
}
function getCodeImg() {
  return (0, _request.default)({
    url: 'auth/code',
    method: 'get'
  });
}
function logout() {
  return (0, _request.default)({
    url: 'auth/logout',
    method: 'delete'
  });
}